import Vuex from 'vuex'
import request from '@/api.js'
import CreatePersistedState from 'vuex-persistedstate'

const state = {

	list:[],
	dklist:[],
	// 打卡历史数据
	historylist:[],
	//历史记录数据
	historyrecoderlist:[],
	//全体员工姓名
	nameemployee:[]




}
const mutations = {

	setlist(state, payload) {
		state.list = payload
	},
	setdklist(state,payload){
		state.dklist=payload
		console.log(state.dklist,'state.dklist');
	},
	sethistorylist(state,payload){
		state.historylist=payload
		console.log(state.historylist,'state.historylist');
	},
	sethistoryrecoderlist(state,payload){
		state.historyrecoderlist=payload
		console.log(state.historyrecoderlist,'state.historyrecoderlist');
	},
	setnameemployee(state,payload){
		state.nameemployee=payload
		console.log(state.nameemployee,'state.nameemployee');
	}

}
const actions = {
 async  getlist({commit}){
	let list=[]
	await request('/ClientController/getlist','post').then(data=>{
		console.log(data,'data');
		data.data.data.map(v=>{
		list.push({
			address:v["地址"],
			id: v.id,
			longitude: v['经度'],
			latitude: v['纬度'],
			phones: v['手机'],
			type: 2,
			m: '',
			level: v.lever,
			text: v['备注'],
			city: '',
			heavy:v['产量'],
			name: v['客户名'],
			'客户id':v.id,
			khaddress:v['地址']
			})
		}) 
		commit('setlist',list)
	})
   },
   async getoptions({commit}) {
	await request('/employedManage/getallname','get').then(data=>{commit('setnameemployee',data.data.data)})
  },
}
const getter = {


}

// vuex-persistedstate默认持久化所有state 若不需要全部则把需要持久化的数据放到reducer里 比如 showHttpLoading、unionId 需要持久化 userCode不需要则不需要加入
const vuexPersisted = new CreatePersistedState({
	key: 'VuexPersisted',
	// storage: window.sessionStorage, // 可选择 localStorage、sessionStorage、cookie 看需求 
	// reducer: state => ({
	//   // loading
	//   showHttpLoading: state.showHttpLoading,
	//   // unionId
	//   unionId: state.unionId,
	// })
})

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	getter,
	plugins: [vuexPersisted]
})
export default store