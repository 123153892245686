<template>
  <div id="app">
    <Mapview></Mapview>
  </div>
</template>
<script>
import Mapview from "./components/MapContainer.vue"
import request from '@/api'
import { nextTick } from 'vue';
export default {
  name: 'App',
  components: {
    Mapview
  },
  data(){
    return{
      markerList:[]
    }
  },
  created(){
   
      this.getlist()
  

  },
  methods:{
    async getlist(){
        let list=[];
       await request('/ClientController/getlist','post').then(data=>{
          console.log(data,'data');
          data.data.data.map(v=>{
             
          list.push({
              address:v["地址"],
              id: v.id,
              longitude: v['经度'],
              latitude: v['纬度'],
              phones: v['手机'],
              type: 2,
              m: '',
              level: v.lever,
              text: v['备注'],
              city: '',
              heavy:v['产量'],
              name: v['客户名'],
              '客户id':v.id,
              khaddress:v['地址'],
              cls:v['产量'],
              gc:v['钢厂'],
              deice:v['设备']
              })


          }) ;

         

          nextTick(()=>{
              this.markerList.push(...list)
              
          }) 
         this.$store.commit('setlist',list)
         if(this.$store.state.nameemployee.length==0){
            this.$store.dispatch('getoptions')
         }
       
    // console.log(this.$store.state.list,'this.$store.state.list');

        })
       },
  }
}
</script>

<style lang="less">
@import url('@/assets/font/font/iconfont.css');

 ul,li,p,h1,h2,h3,h4,h5,h6{
        padding: 0 ;
        margin: 0;
      }
  #app {
  
    height: 100%;
    width: 100%;
    margin: 0;
    
  }
</style>
