<template>
  <div style="font-size: 18px;width: 100%;" :style="[{ height: height }]">
    <!-- 高德导航板块 -->
    <div id="panel" style="position:relative;right: 0px;top: 0px;width: 100%;z-index: 100;"></div>
    <!-- 高德地图 -->
    <div id="container"> </div>

    <!-- 右上角按钮 -->
    <div
      style="width: 50px;height: 50px;background-color: #fff;text-align: center;line-height:50px;z-index: 101;position: absolute;right: 10px;top: 10px;border-radius: 25px;box-shadow: 4px 4px 4px #d7d3d3;"
      @click="planeishowfun()" v-show="planeishow == false">
      <img src="@/assets/postion3.png" alt=""
        style="height: 30px;width: 30px;text-align: center;line-height: 30px;vertical-align: middle;margin-top: -4px;">
    </div>


    <!-- 添加标记点 -->
    <div class="info" @touchmove="planeishow = false" v-show="planeishow" style="z-index: 101;">
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">当前标记点</span>
        </div>
        <input id='tipinput' type="text" style="font-size:16px" v-model="geoText">
      </div>

      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">客户名称</span>
        </div>
        <input id='' type="text" v-model="khname">
      </div>
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">客户手机号</span>
        </div>
        <input id='' type="text" v-model="khphone">
      </div>
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">产量</span>
        </div>
        <input id='' type="text" v-model="cls">
      </div>
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">钢厂</span>
        </div>
        <input id='' type="text" v-model="gc">
      </div>
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">设备</span>
        </div>
        <input id='' type="text" v-model="deice">
      </div>
      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text" style="width:8rem;">备注</span>
        </div>
        <input id='' type="text" v-model="khtext">
      </div>

      <div class="input-item">
        <div class="input-item-prepend">
          <span class="input-item-text input-item-text2" style="width:8rem;" @click="submitadd()">确定添加标记点</span>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <!-- <div @click="make_geo">定位:{{ geoText }}</div> -->
      <!-- <div @click="compdiatance">计算总距离</div> -->

      <!-- <input type="text" v-model="makepalce"> -->
      <!-- <button @click="addmaker">添加标记点</button> -->
      <!-- <button @click="autoOptions">查找并标记</button> -->



      <div style="position: relative;top: -100px;z-index: 999;">
        <!-- 轮播图 评价-->
        <!-- 任务板块 -->
        <div class="planework">
          <!-- 导航 -->
          <swiper :slides-per-view="3" :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide>
              <div :class="[slect == 1 ? 'slect' : '']" @click="getsqlist(1)">记录</div>
            </swiper-slide>
            <swiper-slide>
              <div :class="[slect == 2 ? 'slect' : '']" @click="getdakdata(2)">打卡</div>
            </swiper-slide>
            <swiper-slide>
              <div :class="[slect == 4 ? 'slect' : '']" @click="slect = 4">申请</div>
            </swiper-slide>
            <!-- <swiper-slide>
              <div :class="[slect == 5 ? 'slect' : '']" @click="slect = 3">评价</div>
            </swiper-slide> -->
          </swiper>

          <!-- 记录 -->
          <div class="sqList" v-show="slect == 1 && sqlistall.length != 0">
            <div style="border-bottom: 1px solid rgb(170, 170, 170); padding-bottom: 15px;">
              <div class="sq_item" v-for="item in sqlistall" :key="item.id" @click="fiddetailbyid(item)">
                <ul class="ul">
                  <li v-if="item.officialBusiness.status == '待审核' && item.officialBusiness.submitstatus == '0'"
                    style="color:orange;">状态:{{ item.officialBusiness.status }}</li>
                  <li v-else-if="item.officialBusiness.status == '已拒绝' && item.officialBusiness.submitstatus == '0'"
                    style="color:rgb(255, 34, 0);">状态:{{ item.officialBusiness.status }}</li>
                  <li v-else-if="item.officialBusiness.status == '已通过' && item.officialBusiness.submitstatus == '0'"
                    style="color:rgb(255, 34, 0);">状态:{{ item.officialBusiness.status }}</li>

                  <li v-else style="color:green;">状态:已完成 </li>

                  <li>审核人:</li>
                  <li>出差人员:{{ item.officialBusiness.ccperson }}</li>
                  <li>共同出差人员:{{ item.officialBusiness.ccpersonwith }}</li>
                  <li>申请时间:{{ item.officialBusiness.sqtime }}</li>
                  <li @click="cximg(1, item)" style="color: rgb(121, 121, 223);"
                    v-if="item.officialBusiness.submitstatus == '1' && item.officialBusiness.begintime ">开始时间:{{ item.officialBusiness.begintime }}</li>
                  <li v-if="item.beginimg == 1">
                    <img :src="item.officialBusiness.beginimg" alt="" style="width: 300px;height: 300px;">

                  </li>
                  <li @click="cximg(2, item)" style="color: rgb(121, 121, 223);"
                    v-if="item.officialBusiness.submitstatus == '1' && item.officialBusiness.finishtime" >结束时间:{{ item.officialBusiness.finishtime }}</li>
                  <li v-if="item.endimg == 2">
                    <img :src="item.officialBusiness.endimg" alt="" style="width: 300px;height: 300px;">

                  </li>
                  <li>总里程:{{ item.officialBusiness.alldistance }}km</li>

                  <li>外出条列
                    <ul>
                      <li v-for="(item2, index) in item.officialBusinessDetialList" :key="index"
                        v-show="item.officialBusinessDetialList.length != 0">
                        <p style="font-size: 16px;font-weight: 600;">客户:{{ item2.name }}</p>
                        <p style="font-size: 16px;">地址:{{ item2.khaddress }}</p>
                        <p style="font-size: 16px;">打卡时间:{{ item2.dktime }} </p>
                        <p style="font-size: 16px;">打卡位置:{{ item2.address }} </p>
                      </li>
                      
                    </ul>
                  </li>
                  <li  v-show="item.officialBusiness.status == '已拒绝'  "  @click="cxsq(item)"> 
                   <el-button type="primary"> 
                    重新申请 
                   </el-button>
                  </li>

                </ul>

              </div>
            </div>
          </div>
          <!-- 打卡 -->
          <div class="sqList2" v-show="slect == 2 && getdakdatalist.length != 0">
            <div class="sq_item2" v-for="(item, index) in getdakdatalist" :key="item.id">
              <div style="display: flex;margin: 10px 0;">
                <p>开始里程附件:</p>
                <el-upload class="avatar-uploader" :action='uploadurl' :show-file-list="false"
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                  :disabled="item.officialBusiness.beginimg">

                  <img v-if="imageUrl != 'data:image/jpeg;base64,null'" :src="imageUrl" class="avatar" />
                  <el-icon v-else class="avatar-uploader-icon" style="width: 100px;height: 100px;">
                    <Picture />
                  </el-icon>
                </el-upload>
              </div>

              <ul class="ul2">
                <li v-for="item2 in item.officialBusinessDetialList" :key="item2.id">
                  <img src="@/assets/circal.png" style="width: 14px; height: 14px;" alt="" v-if="item2.dktime != null">
                  <img src="@/assets/circaln.png" alt="" style="width: 14px; height: 14px;" v-else>
                  <div :class="[getdakdatalist.length == index ? 'onboderleft' : '']" class="div">

                    <div>
                      <p>{{ item2.name }} {{ item2.phones }}</p>

                      <p>地址:{{ item2.khaddress }}</p>
                    </div>
                    <div class="span" @click="fightcart(item2)" v-if="!item2.dktime">🏷️打卡</div>
                    <div v-else style="background-color: orange;border: 1px solid orange;" class="span">🏷️打卡成功</div>
                    <div style="width: 90%;">
                      <div style="margin-top: 10px;display: flex;" v-if="item2.address != null"> <i
                          class="iconfont icon-_didian"></i> {{ item2.address }}</div>
                      <div style="margin-left: 10px;" v-if="item2.dktime != null">{{ item2.dktime }}</div>
                    </div>
                    <div class="lever" v-if="item2.dktime != null">
                      <p>
                        <span style="display: flex;flex-direction: column;overflow: hidden;">评价:</span>
                        <textarea name="" id="" cols="20" rows="4" style="outline: none;font-size: 18px;"
                          v-model="item2.pjtextkh"></textarea>
                        <view class="ico">
                          <view class="inners" @click="gettitle(item2, 1)">
                            <!-- <span style='font-size: 10px;'>差</span> -->
                            <span class='iconfont' :class='{ cli: item2.star >= 1 }' v-if="item2.star >= 1">&#xe60d;</span>
                            <i class='iconfont' v-if="!(item2.star >= 1 == true)">&#xe621;</i>
                          </view>
                          <view class="inners" @click="gettitle(item2, 2)">
                            <span class='iconfont' :class='{ cli: item2.star >= 2 }' v-if="item2.star >= 2">&#xe60d;</span>
                            <span class='iconfont' v-if="!(item2.star >= 2 == true)">&#xe621;</span>
                          </view>
                          <view class="inners" @click="gettitle(item2, 3)">
                            <span class='iconfont' :class='{ cli: item2.star >= 3 }' v-if="item2.star >= 3">&#xe60d;</span>
                            <span class='iconfont' v-if="!(item2.star >= 3 == true)">&#xe621;</span>
                          </view>
                          <view class="inners" @click="gettitle(item2, 4)">
                            <span class='iconfont' :class='{ cli: item2.star >= 4 }' v-if="item2.star >= 4">&#xe60d;</span>
                            <span class='iconfont' v-if="!(item2.star >= 4 == true)">&#xe621;</span>
                          </view>
                          <view class="inners" @click="gettitle(item2, 5)">
                            <span class='iconfont' :class='{ cli: item2.star == 5 }' v-if="item2.star == 5">&#xe60d;</span>
                            <span class='iconfont' v-if="!(item2.star == 5)">&#xe621;</span>
                          </view>
                        </view>

                      </p>
                    </div>
                  </div>
                </li>

              </ul>
              <!--        
              <div style="display: flex;margin-top:20px">
                <p>结束里程附件:</p>
                <el-upload
                   class="avatar-uploader"
                   :action='uploadurl'
                   :show-file-list="false"
                   :on-success="handleAvatarSuccessend"
                   :before-upload="beforeAvatarUpload"
                    :disabled="item.officialBusiness.endimg"
                 >
                   <img v-if="imageUrlend!='data:image/jpeg;base64,null'" :src="imageUrlend" class="avatar" />
                   <el-icon v-else class="avatar-uploader-icon" style="width: 100px;height: 100px;"><Picture /></el-icon>
                 </el-upload>
              </div> -->
              <div style="position: absolute;right: 20px;bottom: 0;" class="submit" @click="submitdk()">提交</div>

            </div>
          </div>

          <!-- 申请 -->
          <div class="innner_work" style="position: relative;top: 0;" v-show="slect == 4 && sqLists.length != 0">
            <div class="sq">
              <div class="item_sq">
                <ul>
                  <li style="display: flex;justify-content: space-between;margin-top: 10px;" v-for="(item) in sqLists"
                    :key="item.id">

                    <div
                      style="position: relative ;top: 0;display: flex;justify-content: space-between;width: 100%;border-bottom: 1px solid #ccc;padding: 0 5px;">
                      <div>
                        <div style="position: absolute;top: 0;left: -20px;">🚖</div>
                        <div>{{ item.name }}</div>
                        <div> {{ item.address }}</div>
                      </div>
                      <div @click="deleitem(item)"
                        style="width: 24px; height: 24px;text-align: center;line-height: 18px;background-color: #ffffff;border-radius: 15px; ">
                        <img src="@/assets/delete.png" alt="">

                      </div>
                    </div>

                  </li>
                </ul>
                <!-- 共同报销人 -->

                <div style="display: flex;margin: 20px;" v-show="sqLists.length != 0">
                  <div>共同出差人:</div>
                  <el-select v-model="optionsvalue">
                    <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item" />
                  </el-select>
                </div>
                <!-- 提交 -->
                <div style="position: absolute;right: 20px;bottom: 0;" class="submit" @click="submit()"
                  v-show="sqLists.length != 0">提交申请
                </div>
              </div>
            </div>
          </div>

     

          <!-- 空 -->
          <div class="none"
            v-if="(slect == 2 && getdakdatalist.length == 0) || (slect == 4 && sqLists.length == 0) || (slect == 1 && sqlistall.length == 0)">
            <img src="@/assets/none.png" alt=""
              style="width: 250px;height: 250px;text-align: center;margin-left: 50%;transform: translateX(-50%);margin-top: -5%;">
          </div>



        </div>

      </div>

    </div>
    <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #999;margin-left: 10px;border-bottom: 0;display: fixed;bottom: 10px;font-size: 14px;text-decoration: none;">闽ICP备2023005021号-1</a>

  </div>
</template>

<script>
// Import Swiper styles
import 'swiper/css';

import { nextTick } from 'vue';

import {
  ElLoading,
  ElMessage,
} from 'element-plus';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/vue';

import request, { baseURL } from '@/api';
import AMapLoader from '@amap/amap-jsapi-loader';
import { Picture } from '@element-plus/icons-vue';

// import axios from 'axios'
export default {
  components: { Swiper, SwiperSlide, Picture },
  name: "map-view",
  data() {
    return {

      height: window.innerHeight,
      beginimg: '',
      endimg: '',
      map: '',
      marker: '',
      cl: [1, 2],
      xx: ['1', '2'],
      latitude: '',
      longitude: '',
      src: '',

      position: [],
      restan: '',
      lnglat: [117.639047, 26.272784],
      markerList: [],
      geolocation: '',
      AMap: '',
      geoText: '',
      planeishow: false,
      makepalce: '',
      placeSearch: '',



      city: '',
      detailaddress: '',
      instanspoint: null,
      sqLists: [],//申请列表,
      slect: 2,
      star: 0,
      timer: null,
      selectitem: 0,
      options: ['无'],
      optionsvalue: '',
      imageUrl: "",
      imageUrlend: "",
      uploadurl: '',

      based64img: '',
      name: '测试',
      nameOffical: '测试',
      sqlistall: [],
      getdakdatalist: [],
      lng: '',
      lat: '',
      addtextss: '',
      khphone: '',
      khtext: '',
      khname: '',
      compdistan: null,
      nametouchend: "",
      cls: '',
      gc: '',
      deice: '',
      dshsqlistall:null,
    



    }
  },
  created() {

    this.uploadurl = baseURL + '/OfficialBusinessController/transterurl'
    let that = this

var params = window.location.search;
var paramValue = new URLSearchParams(params).get('name');
if (paramValue != null) {
 that.nameOffical = paramValue
 //  alert(that.nameOffical)
}

console.log(that.nameOffical,'that.nameOffical')

  },
  mounted() {

    this.options=this.$store.state.nameemployee
console.log(this.options,'that.options')
    // 高精度定位所需的key
    window._AMapSecurityConfig = {
      securityJsCode: '2de257a12bd1edaea9d42b388d6842d6',
    }

    // 粗定位
    // axios.get('https://restapi.amap.com/v3/ip?key=3365ed47b2ca5e7db71bd031d57aa8c6').then(data => {
    //   let rectangle = data.data.rectangle
    //   let ss = rectangle.split(';')
    //   this.restan = ss[0]
    //   this.lnglat = this.restan.split(',')
    //   console.log(this.lnglat, ' this.lnglat');
    // })
   
    this.getsqlist(0)
    this.getdakdata(2)

    this.initAMap();
    // 获取打卡信息
    // 获取申请列表

    // that.timer = setInterval(() => {
      this.make_geo()
    // }, 5000)
   
    // 获取员工列表
    // this.getoptions()

  },
  unmounted() {
    this.map?.destroy();
    this.$store.commit('setlist', [])

    // clearInterval(this.timer)
  },
  methods: {
    // 初始化地图
    initAMap() {
      AMapLoader.load({
        key: "812773677d50c618c44eb64c51eca010", // 申请好的Web端开发者Key,首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本,缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.ElasticMarker', 'AMap.Geolocation', 'AMap.Scale', 'AMap.Geocoder', 'AMap.Driving', 'AMap.PlaceSearch', 'AMap.AutoComplete'], // 需要使用的的插件列表,如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 8, // 初始化地图级别
            center: [117.674519, 26.327189], // 初始化地图中心点位置
          });
          this.map.on('click', this.clickHandler);


          this.markerList = this.$store.state.list
          // console.log( this.markerList,' this.markerList');

          for (let i = 0; i < this.markerList.length; i++) {
            if (this.markerList[i].type == 2) {
              this.markerList[i].m = new AMap.Marker({
                position: [this.markerList[i].longitude, this.markerList[i].latitude],
                // icon: `https://webapi.amap.com/theme/v1.3/markers/n/mark_b${i+1}.png`,
                icon: require('../assets/position.png'),
                title: this.markerList[i].name,
                anchor: 'bottom-center',
                offset: new AMap.Pixel(0, 0),
                clickable: true,

              });
            } else {
              this.markerList[i].m = new AMap.Marker({
                position: [this.markerList[i].longitude, this.markerList[i].latitude],
                // icon: `https://webapi.amap.com/theme/v1.3/markers/n/mark_b${i+1}.png`,
                icon: require('../assets/position.png'),
                title: this.markerList[i].name,
                anchor: 'bottom-center',
                offset: new AMap.Pixel(0, 0),
                clickable: true,

              })
            }

            this.markerList[i].m.setMap(this.map);
            // 设置鼠标划过点标记显示的文字提示
            this.markerList[i].m.setTitle('我是marker的title');
            // 设置label标签
            // label默认蓝框白底左上角显示,样式className为:amap-marker-label
            this.markerList[i].m.setLabel({
              direction: 'right',
              icon: '1111',
              offset: new AMap.Pixel(10, 10),  //设置文本标注偏移量
              content: `
              <div class='amap-marker-label' style="padding:20px 5px 5px 5px">
                <div class="close-btn${i} close-btn"></div>
                <div class="zhon-btn${i} zhon-btn" ></div>
                <p class="name${i}" style="font-size:12px"> ${this.markerList[i].name}    <span class="pj${i}" ><span class='span${i}'></span></span> </p> 
                <div class="detial${i}" style='display:none;'>
                   <p class='spanphone${i}'>电话:${this.markerList[i].phones}</p>
                   <p>产量:${this.markerList[i].heavy}</p>
                   <p>钢厂:${this.markerList[i].gc}</p>
                   <p>设备:${this.markerList[i].deice}</p>
                  
                   <p class="address${i}">${this.markerList[i].address}</p>   
                   <input type="text"  class="input${i} input" style="width:150px;boder:none;outline:none;display:none;">
                   <p class="text${i}"> 备注(点击更改):${this.markerList[i].text}</p>
                </div>

              </div>`

              , //设置文本标注内容
            });


            // document.querySelector(`.spanphone${i}`).addEventListener('click', () => {
            //   navigator.clipboard.writeText(document.querySelector(`.spanphone${i}`).innerHTML).then(() => {
            //     alert(`复制成功:${document.querySelector(`.spanphone${i}`).innerHTML}`)

            //   })
            // })
            // document.querySelector(`.address${i}`).addEventListener('click', () => {
            //   navigator.clipboard.writeText(document.querySelector(`.address${i}`).innerHTML).then(() => {
            //     alert(`复制成功:${document.querySelector(`.address${i}`).innerHTML}`)
            //   })
            // })
            document.querySelector(`.input${i}`).addEventListener('touchend', () => {
              console.log('input', i, document.querySelector(`.input${i}`).value);
            })
            document.querySelector(`.input${i}`).addEventListener('blur', () => {
              document.querySelector(`.text${i}`).style.display = 'block'
              document.querySelector(`.input${i}`).style.display = 'none'
              document.querySelector(`.text${i}`).innerHTML = document.querySelector(`.text${i}`).innerHTML + document.querySelector(`.input${i}`).value
              this.markerList.forEach(v => {
                if (v.id == i) {
                  v.text = document.querySelector(`.text${i}`).innerHTML

                }
              })
              this.addtextss = document.querySelector(`.input${i}`).value
              this.addtextsv(this.markerList[i])

          

              console.log('input', this.markerList[i], document.querySelector(`.input${i}`).value);

            })
            document.querySelector(`.text${i}`).addEventListener('touchend', () => {
              document.querySelector(`.input${i}`).style.display = 'block'
              document.querySelector(`.text${i}`).style.display = 'none'
              console.log('text', i, document.querySelector(`.text${i}`).value);

            })
            switch (this.markerList[i].level) {
              case '1':
                document.querySelector(`.span${i}`).innerHTML = '⭐'
                break;
              case '2':
                document.querySelector(`.span${i}`).innerHTML = '⭐⭐'
                break;
              case '3':
                document.querySelector(`.span${i}`).innerHTML = '⭐⭐⭐'
                break;
              case '4':
                document.querySelector(`.span${i}`).innerHTML = '⭐⭐⭐⭐'
                break;
              case '5':
                document.querySelector(`.span${i}`).innerHTML = '⭐⭐⭐⭐⭐'
                break;
              default:
                document.querySelector(`.span${i}`).innerHTML = '无评价'

                break;
            }

            document.querySelector(`.close-btn${i}`).addEventListener('touchend', () => {
              console.log('close-btn增加', i);
         
              let n = this.sqLists.map(v => v.id == this.markerList[i].id)
              console.log(n, 'n',this.getdakdatalist)
              if (this.getdakdatalist.length == 0 && !this.dshsqlistall.includes(true)) {
                if (n.includes(true)) {
                  alert("已添加")

                } else {
                  this.slect=4
                  this.sqLists.push(this.markerList[i])

                }
              } else {
                alert("您有任务未完成")

              }

            })

            document.querySelector(`.zhon-btn${i}`).addEventListener('touchend', () => {
              console.log('zhon-btn终点', i);
              this.bycart(this.markerList[i])

            })
            document.querySelector(`.name${i}`).addEventListener('touchend', () => {
              // document.querySelector(`.detial${i}`).style.display="block"

              if (document.querySelector(`.detial${i}`).style.display == "block") {
                document.querySelector(`.detial${i}`).style.display = "none"
              } else {
                document.querySelector(`.detial${i}`).style.display = "block"
              }
            })

            this.markerList[i].m.on('touchend', () => {
              console.log(this.markerList[i], i);
              // this.nametouchend = this.markerList[i].name

            })


          }
          // 获取定位详细地址
          this.geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,//是否使用高精度定位,默认:true
            // timeout: 10000, //超过10秒后停止定位,默认:无穷大
            maximumAge: 1000, //定位结果缓存0毫秒,默认:0
            convert: true, //自动偏移坐标,偏移后的坐标为高德坐标,默认:true
            showButton: false, //显示定位按钮,默认:true
            buttonPosition: 'LB', //定位按钮停靠位置,默认:'LB',左下角
            buttonOffset: new AMap.Pixel(0, 0),//定位按钮与设置的停靠位置的偏移量,默认:Pixel(10, 20)
            showMarker: true, //定位成功后在定位到的位置显示点标记,默认:true
            showCircle: false, //定位成功后用圆圈表示定位精度范围,默认:true
            panToLocation: false, //定位成功后将定位到的位置作为地图中心点,默认:true
            zoomToAccuracy: false //定位成功后调整地图视野范围使定位位置及精度范围视野内可见,默认:false

          });

          this.map.addControl(this.geolocation);
          //position
          this.make_geo()
 

        })





        .catch((e) => {
          console.log(e);
        });


    },
    // 获取定位列表
    async getlist() {
      let list = [];
      await request('/ClientController/getlist', 'post').then(data => {
        console.log(data, 'data');
        data.data.data.map(v => {

          list.push({
            address: v["地址"],
            id: v.id,
            longitude: v['经度'],
            latitude: v['纬度'],
            phones: v['手机'],
            type: 2,
            m: '',
            level: v.lever,
            text: v['备注'],
            city: '',
            heavy: v['产量'],
            name: v['客户名'],
            '客户id': v.id,
            khaddress: v['地址'],
            cls: v['产量'],
            gc: v['钢厂'],
            deice: v['设备']
          })


        });
        let that=this

        nextTick(() => {
          that.markerList = list
        })
        that.$store.commit('setlist',list)
        that.initAMap()
        console.log( '2', that.$store.state.list)

      })
    },
    //  获取员工列表
    // async getoptions() {
    //   await request('/employedManage/getallname', 'get').then(data => {
    //     this.options.push(...data.data.data)
    //     console.log(  this.options,'  this.options');
    //   })
    // },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },
    // 定位
    make_geo() {
      let that = this
      that.geolocation.getCurrentPosition(function (status, result) {
        that.onComplete(result)
        var geocoder = new that.AMap.Geocoder();
        geocoder.getAddress([result.position?.lng, result.position?.lat], function (status, result) {
          that.geoText = result?.regeocode?.formattedAddress

          console.log(result?.regeocode?.formattedAddress, '定位详细');
        });
      });
    },
    onComplete(data) {
      // console.log(data, '定位');
      // this.city = data.regeocode?.addressComponent?.city
      // this.detailaddress = data?.regeocode?.formattedAddress
      this.city = data.position.lng
      this.lng = data.position.lng
      this.detailaddress = data.position.lat
      this.lat = data.position.lat
      // console.log(this.detailaddress, ' this.detailaddress', this.city, '   this.city');
    },
    // 驾车
    bycart(data) {
      // console.log('驾车', data);

      this.planeishow = false
      var driving = new this.AMap.Driving({
        map: this.map,
        panel: "panel"
      })
      // 根据起终点名称规划驾车导航路线
      let that = this
      driving.search(
        // [ { keyword: this.detailaddress, city: this.city },  { keyword: data.address, city: data.city }]
        new that.AMap.LngLat(that.city, that.detailaddress),
        new that.AMap.LngLat(data.longitude, data.latitude)
        , function (status, result) {
          // result 即是对应的驾车导航信息,相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === 'complete') {
            console.log(result, '驾车路线查询', '最短距离:', result.routes[0].distance / 1000);
          } else {
            console.log(result);
          }
        });


    },
    //计算距离
    compdiatance() {
      let list = []
      let dot = this.getdakdatalist[0].officialBusiness?.beginlnglog
      console.log(dot, 'dot')
      let ldot;
      if (dot != null) {
        ldot = dot.split(",")

        let dotobj = { lng: ldot[0], lat: ldot[1] }
        list.push(dotobj)
        let dotlist = this.getdakdatalist[0].officialBusinessDetialList.filter(v => v.dktime != null)

        for (let j = 0; j < dotlist.length; j++) {
          let ldots = dotlist[j].lnglog.split(",")
          let dotobjs = { lng: ldots[0], lat: ldots[1] }
          list.push(dotobjs)

        }
        if(list.length>1){
         

        //  let dote = this.getdakdatalist[0].officialBusiness?.endlnglog
        //  let ldote;
        //   if( dote!=null ){
        //    ldote=  dote.split(",")

        //       let dotobje={lng:ldote[0],lat:ldote[1]}
        //       list.push(dotobje)

        //   }else{
        //     alert('您未提交结束里程图片!')
        //     return null;
        //   }

         console.log(list, 'list');
          this.planeishow = false
         let compdistan = 0;
         var driving = new this.AMap.Driving({
          map: this.map,
          panel: ""
         })
         let that=this
         for (let i = 0; i < list.length - 1; i++) {
          // 根据终点名称规划驾车导航路线
           driving.search(
          new that.AMap.LngLat(list[i].lng, list[i].lat),
          new that.AMap.LngLat(list[i + 1].lng, list[i + 1].lat), 
          function (status, result) {
            // result 即是对应的驾车导航信息,相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
            if (status === 'complete') {
              compdistan += result.routes[0].distance / 1000
              console.log(compdistan, 'compdistan');

              that.compdistan = compdistan
            } else {
              console.log(result);
            }
            });
            
             console.log(that.compdistan, 'this.compdistan');


           }

           }else{
           alert('未打卡!')
             
           }

      } else {
        alert('未提交开始里程图片!')
      
      }




    },

    // 添加标记点
    autoOptions() {

      let that = this
      that.placeSearch = new that.AMap.PlaceSearch({
        city: '全国'
      });  //构造地点查询类
      that.placeSearch.search([this.lng, this.lat], function (status, result) {
        // 搜索成功时,result即是对应的匹配数据
        console.log(result, '查询数据坐标');

        // var pois = result?.poiList?.pois;
        // var poi = pois[0];
        // console.log(poi.location, ' poi.location');
        var marker = [];
        marker[0] = new that.AMap.Marker({
          icon: require('../assets/postion3.png'),
          position: [this.lng, this.lat],   // 经纬度对象,也可以是经纬度构成的一维数组[116.39, 39.9]
          title: that.geoText
        });
        // 将创建的点标记添加到已有的地图实例:
        that.map.add(marker[0]);
        that.instanspoint = {
          address: that.geoText,
          longitude: that.lng,
          latitude: that.lat,
          name: that.khname,
          phones: that.khphone,
          type: 0,
          m: '',
          level: 4,
          text: that.khtext,
        }
        that.map.setFitView();
      })

    },
    //确定添加新的标记点
 async   submitadd() {
      if (this.khname == '' || this.khphone == '') {
        alert('信息未填写完整')
      } else {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
      await  request('/ClientController/save', 'post', {
          '客户名': this.khname,
          '地址': this.geoText,
          '备注': this.khtext,
          '手机': this.khphone,
          '纬度': this.lat,
          '经度': this.lng,
          '业务员': this.nameOffical,
          '产量': this.cls,
          '钢厂': this.gc,
          '设备': this.deice

        }).then(async data => {
          console.log(data, '添加坐标点');
          alert(data.data.msg)
          this.planeishow = false
          this.khname = ''
          this.khtext = ''
          this.khphone = ''
          this.cls = ''
          this.gc = '',
            this.deice = ''

      await   this.getlist()
         loading.close()


        })

      }


    },
    selecyloction() {
      console.log(this.selecyloction);
    },
    // 删除清单item
    deleitem(item) {
      this.sqLists = this.sqLists.filter(v => v.id != item.id)
    },
    // 添加清单
    addposion(item) {
      console.log('close-btn增加');
      let n = this.sqLists.map(v => v.id == item.id)
      console.log(n, 'n')
      if (n.includes(true)) {
        alert("已添加")

      } else {
        this.sqLists.push(item)

      }
    },
    // 更改 添加 备注
    addtexts(item) {
      this.selectitem = item.id
      console.log(this.selectitem, item.id);
      this.markerList.forEach(v => {
        if (v.id == item.id) {
          v.text = item.text
        }
      })
      console.log('添加');
    },


    // 评分⭐
    gettitle(item, score) {
      this.getdakdatalist[0].officialBusinessDetialList.forEach(v => {
        if (v.id == item.id) {
          v.star = score
        }
      })



    },
    // 增加评价
    async addtextsv(item) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      console.log("addtexts", item, this.addtextss);
           let that=this
      await request('/ClientController/addtexts', 'post', {
        id: item.id,
        "备注": that.addtextss,
      }).then(data => {
        console.log( data);

        loading.close()
      })

    },
    // 开始里程上传成功
    handleAvatarSuccess(response, uploadFile) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.imageUrl = URL?.createObjectURL(uploadFile?.raw)
      this.based64img = response.data
      this.updaloadimg(1)
      loading.close()
      console.log(response, 'response');
    },
    // 结束里程上传成功
    handleAvatarSuccessend(response, uploadFile) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.imageUrlend = URL?.createObjectURL(uploadFile?.raw)
      this.based64img = response.data
      console.log(response.data, '结束里程附件');
      this.updaloadimg(2)
      loading.close()


    },
    // 上传之前
    beforeAvatarUpload(rawFile) {
      console.log(rawFile, 'rawFile', rawFile.rows);
      if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('图片大小不超过 2MB!')
        return false
      }


    },
    // 上传里程图片
    async updaloadimg(i) {
      let that = this
      let id = that.getdakdatalist[0].officialBusiness.id
      that.make_geo()
      that.$store.commit("sethistorylist",[])
      if (i == 1) {
        await request(`${baseURL}/OfficialBusinessController/uploadimg`, 'post', {
          'beginimg': that.based64img,
          'id': id,
          'latlng': `${that.lng},${that.lat}`,
          "name":that.nameOffical

        }).then(data => {
          console.log("上传里程图片开始", data);
        })
      } else {
        await request(`${baseURL}/OfficialBusinessController/uploadimgend`, 'post', {
          'endimg': that.based64img,
          'id': id,
          'latlng': `${that.lng},${that.lat}`,
          "name":that.nameOffical

        }).then(data => {
          console.log("上传里程图片结束", data);
        })
      }
    },
    // 重新提交出差申请
   async cxsq(item){
   await this.fiddetailbyid(item)
    console.log(item);
     const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      item.officialBusinessDetialList.forEach(v=>{
        v.latitude=''
        v.longitude=''
        v['客户id']=''
        v.address=''
        v.latitude=v.lat
        v.longitude=v.lng
        v['客户id']=v.custid
        v.address=v.khaddress

      })
        this.sqLists.push(...item.officialBusinessDetialList)
        this.slect = 4
        loading.close()
  
    },
    // 提交出差申请
    async submit() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      console.log(this.sqLists, 'this.sqlist')

      await request('/OfficialBusinessController/save', 'post', {
        'ccperson': this.nameOffical,
        'ccpersonwith': this.optionsvalue,
        'visitnum': this.sqLists.length,
        'ccid': '123456'
      }).then(async data => {
        console.log('申请', data.data.data);

        let list = []
        this.sqLists.map(v => {
          list.push({
            'businessid': parseInt(data.data.data),
            'custid': parseInt(v['客户id']),
            'khaddress': v.khaddress,
            'name': v.name,
            'lat': v.latitude,
            'lng': v.longitude

          })
        })

        for (let i = 0; i < list.length; i++) {
          console.log(list[i], 'list')
          await request('/OfficialBusinessController/savelist', 'post', {

            ...list[i]
          }).then(data => {
            console.log('申请', data);


          })
        }
        this.sqLists = []
        this.$store.commit("sethistoryrecoderlist",[])
        this.$store.commit("sethistorylist",[])


        this.getdakdata(2)
        loading.close()
      })
    },
    // 提交打卡信息
    async submitdk() {
      let that = this
      await that.updateccsubmitstatus()
  




    },
    // 获取历史申请列表
    async getsqlist(i) {
      this.slect = i
    //  if(this.$store.state.historyrecoderlist.length==0){
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      await request('/OfficialBusinessController/getlistall', 'post', {
        'name': this.nameOffical,

      }).then(data => {
        this.sqlistall = data.data.data
     this.dshsqlistall=   this.sqlistall.map(v=>v.officialBusiness.status == '待审核' )
     console.log(this.dshsqlistall,'dshsqlistall');
        // this.$store.commit("sethistoryrecoderlist",this.sqlistall)
        //  data:image/jpeg;base64
        // this.sqlistall.forEach(v=>{
        //   v.officialBusiness.beginimg="data:image/jpeg;base64"+v.officialBusiness.beginimg
        //   v.officialBusiness.endimg="data:image/jpeg;base64"+v.officialBusiness.endimg

        // })
        loading.close()
        console.log(data, '获取历史申请列表')
      })
    // }else{
    //   this.sqlistall=this.$store.state.historyrecoderlist
    // }


    },
    //获取打卡数据
    async getdakdata(i) {

      this.slect = i
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
       
      let that = this
      // that.$store.commit("sethistorylist",[])
console.log(that.$store.state.historylist,'that.$store.state.historylist');
      if(that.$store.state.historylist.length==0){
      await request('/OfficialBusinessController/getlistunfinished', 'post', {
        'name': that.nameOffical,
       'status':'已通过'

      }).then(data => {
        let list=[]
        list = data.data.data

        list.forEach(item => {
          that.imageUrl = `data:image/jpeg;base64,${item.officialBusiness.beginimg}`
          that.imageUrlend = `data:image/jpeg;base64,${item.officialBusiness.endimg}`
          console.log(data, '获取打卡信息2222')
          item.officialBusinessDetialList.forEach(v => {
            v.star = 0
            v.pjtextkh = ''

          })
        })
            that.getdakdatalist=list
            that.$store.commit("sethistorylist", that.getdakdatalist)
        loading.close()
      })
    }else{
      let list=[]
      list=that.$store.state.historylist
      list.forEach(item => {
          that.imageUrl = `data:image/jpeg;base64,${item.officialBusiness.beginimg}`
          that.imageUrlend = `data:image/jpeg;base64,${item.officialBusiness.endimg}`

     
        })
      that.getdakdatalist= that.$store.state.historylist
      loading.close()

    }



    },

    //  打卡
    async fightcart(item) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.make_geo()
      console.log(this.geoText, 'geoText');
      await request('/OfficialBusinessDetailController/updatemsg', 'post', {
        'address': this.geoText,
        'lnglog': `${this.lng},${this.lat}`,//大 小
        'id': item.id,
        'businessid': this.getdakdatalist[0].officialBusiness.id
      }).then(async data => {
        console.log(data, '打卡')
        this.$store.commit("sethistorylist",[])
        // this.$store.commit("sethistoryrecoderlist",[])

        this.getdakdata(2)
        loading.close()
      })

    },
    //  打开面板
    planeishowfun() {
      this.planeishow = true
      this.make_geo()
    },
    // 增加评论

    async addkhtextpj(item) {
      await request('/OfficialBusinessDetailController/updatemsg', 'post', {
        'id': item.id,
        'pjtextkh': item.pjtextkh,
        'star': item.star

      }).then(async data => {
        console.log(data, '增加评论')

      })
    },
    // 改变出差提交状态

    async updateccsubmitstatus() {
      let that = this
    
      await that.compdiatance()
      const loading = ElLoading.service({
              lock: true,
              text: 'Loading',
              background: 'rgba(0, 0, 0, 0.7)',
            })
      if (that.compdistan != null) {
        console.log(that.getdakdatalist[0].officialBusiness.id, 'this.getdakdatalist[0].officialBusiness.id')
        await request('/OfficialBusinessController/statussubmit', 'post', {
          'submitstatus': '1',
          'id': that.getdakdatalist[0].officialBusiness.id,
          "alldistance": that.compdistan,
          "ccperson":that.nameOffical,
          "status":'已完成'

        }).then(async data => {
          console.log(data, '打卡')
          that.compdistan = null
        
          
            let len = that.getdakdatalist[0].officialBusinessDetialList.length
            let list = that.getdakdatalist[0].officialBusinessDetialList
            for (let i = 0; i < len; i++) {
              await request('/ClientController/updatelever', 'post', {
                id: list[i].custid,
                "lever": list[i].star,
                "备注": list[i].pjtextkh
              }).then(data => {
                console.log("提交打卡信息", data);
                //  this.addkhtextpj(list[i])
              })

            }
            that.getdakdata(2)
          
           that.$store.commit("sethistoryrecoderlist",[])
           that.$store.commit("sethistorylist",[])
  loading.close()

            alert("提交成功!")
        })
      } else {
        loading.close()

      return null;
      }

    },
    // 根据id查询详情
    async fiddetailbyid(item) {
      // data:image/jpeg;base64,

      console.log(item, 'item');
      await request('/OfficialBusinessController/getlistallbyid', 'post', {
        id: item.officialBusiness.id
      }).then(data => {
        this.sqlistall.forEach(v => {

          if (v.officialBusiness.id == item.officialBusiness.id) {
            v.officialBusinessDetialList = null
            v.officialBusinessDetialList = data.data.data
          }
        })

        console.log(data.data);
      })
    },
    cximg(i, item) {

      if (i == 1) {
        this.sqlistall.forEach(v => {
          if (v.officialBusiness.id == item.officialBusiness.id) {
            v.officialBusiness.beginimg = 'data:image/jpeg;base64,' + item.officialBusiness?.beginimg
            v.beginimg = i
          }
        })

      } else {

        this.sqlistall.forEach(v => {
          if (v.officialBusiness.id == item.officialBusiness.id) {
            v.officialBusiness.endimg = 'data:image/jpeg;base64,' + item.officialBusiness?.endimg
            v.endimg = i
          }
        })
      }
    },
  }
}
</script>
<style scoped lang="less">
#container {
  width: 100%;
  height: 600px;
}

/deep/ map {
  width: 100%;
  height: 100%;
}

.leverlabel {
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  height: 270px !important;
  box-shadow: 2px 2px 2px 2px #ccc;
  margin-top: 15px;
  position: relative;
  top: 0;

  .close-btn {
    position: absolute;
    top: -10px;
    right: -7px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    // background-color: rgb(98, 143, 205);
    background-color: rgb(255, 255, 255);
    text-align: center;
    line-height: 25px;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 600;
  }

  .zhon-btn {
    position: absolute;
    top: -10px;
    right: 40px;
    width: 30px;
    height: 30px;
    border-radius: 15px;

    background-color: rgb(255, 255, 255);
    text-align: center;
    line-height: 25px;
    color: white;
    font-size: 16px;
  }

  P {
    margin: 0;
    padding: 5px;
  }

}

/deep/.amap-marker-label {
  border: none !important;
  position: relative;
  top: 0;
  box-shadow: 2px 2px 2px 2px rgba(91, 182, 196, 0.1);
  font-size: 12px !important;

  .close-btn {
    position: absolute;
    top: -10px;
    right: -7px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    // background-color: rgb(98, 143, 205);
    // background-color: rgb(239, 185, 86);
    text-align: center;
    line-height: 25px;
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: 600;
    background-image: url("~@/assets/add3.png");
    background-size: contain;
  }

  .zhon-btn {
    position: absolute;
    top: -10px;
    right: 30px;
    width: 30px;
    height: 30px;
    border-radius: 15px;

    background-image: url("~@/assets/position2.png");
    background-size: contain;
    text-align: center;
    line-height: 25px;
    color: rgb(255, 255, 255);
    font-size: 16px;
  }

  P {
    margin: 0;
    padding: 5px;
  }

  .input {
    margin-left: 5px;
    margin-top: 5px;
  }
}

.input-item-text2 {
  margin-left: 150px;
  padding: 4px 5px;
}

.planework {
  background-color: #fff;
  width: 100%;
  padding: 30px;
  padding-bottom: 120px;
  position: relative;
  top: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  .workout {

    display: flex;
    justify-content: space-around;
    font-weight: 700;
    font-size: 18px;
  }

  .sqList {
    margin-top: 20px;

    .sq_item {
      padding: 20px;
      // border: 1px solid #ccc;
      box-shadow: 5px 5px 5px 5px #ccc;
      border-radius: 20px;
      margin-top: 20px;


      .ul>li {
        list-style: none;

        ul {
          margin-left: 30px;
        }
      }
    }
  }

  .sqList2 {
    margin-top: 20px;

    .sq_item2 {
      padding: 15px;
      // border: 1px solid #ccc;
      box-shadow: 5px 5px 5px 5px#ccc;
      border-radius: 20px;
      margin-top: 20px;

      ul>li {
        list-style: none;
        position: relative;
        top: 0;

        img {
          position: absolute;
          top: 0;
          left: -6px;
        }
      }

      .submit {
        background-color: #fff;
        box-shadow: 4px 4px 5px #ccc;
        padding: 5px 10px;
        bottom: -5px !important;
        border: 1px solid orange;
        border-radius: 20px;
        color: rgb(255, 255, 255);
        background-color: orange;
        font-size: 20px !important;
      }

      ul>li .div {
        border-left: 1px solid #ccc;
        // list-style-image: url('~@/assets/定位.png');
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 20px;


        .span {
          border: 1px solid #6297b6;
          padding: 2px 4px;
          font-size: 18px !important;
          border-radius: 10px;
          background-color: #6297b6;
          color: white;
          margin-top: 10px;
          font-size: 700 !important;
        }

        .lever {
          border-radius: 20px;
          border: 1px solid #6297b6;
          padding: 10px 10px;
          margin-top: 20px;
          width: 100%;

          .iconfont {
            font-size: 32px !important;


          }

          .ico {
            padding-top: 40rpx;
            background-color: #fff;
            display: flex;
            justify-content: space-around;

            .inners {
              font-size: 70px;
              display: flex;
              align-items: center;
              flex-direction: column;

              span {
                margin: 5px;
              }

            }
          }
        }
      }


    }
  }

}

.onboderleft {
  border-left: none !important;
}

.slect {
  // color: orange;
  border-bottom: 2px solid orange;

}

.innner_work {
  // background-color: #ccc;
  padding-top: 30px;
  padding-bottom: 80px;
  background-color: #fff;
  // border-bottom: 2px solid orange;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  .sq {


    .item_sq {

      ul>li {
        list-style: none;
        padding: 0 20px;
        font-size: 16px !important;
      }
    }

    .submit {
      background-color: #fff;
      box-shadow: 4px 4px 5px #ccc;
      padding: 5px 10px;
      bottom: -130px !important;
      border: 1px solid orange;
      border-radius: 20px;
      color: rgb(255, 255, 255);
      background-color: orange;
      font-size: 20px !important;
    }
  }
}

.icon_work {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;

  span {
    font-size: 14px;
    font-weight: 500
  }
}

/deep/ .amap-call {
  display: none;
}

.cli {
  color: #ffc73a;
  animation: idle 0.2s linear;
  // transition: stroke 0.2s, fill 0.5s;
}

.info {
  position: absolute;
  right: 0;
  top: 0;
}

/deep/ input[type=text],
input[type=date] {
  background: #fff;
  padding: .375rem .75rem;
  width: 60px !IMPORTANT;
}
</style>

<style>
.avatar-uploader {
  position: relative;
  border: 1px solid var(--el-border-color);

}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
}
</style>